<bfc-single-page-layout>
    <a class="bfc-margin-bottom-2" (click)="goBack()">
        <span class="b2b-back-button">«</span>{{ "BACK_BUTTON" | bfcTranslate }}
    </a>
    <mat-icon fontIcon="bfc-icon-key" class="unauthorized-key"></mat-icon>
    <h1>{{ "UNAUTHORIZED.TITLE" | bfcTranslate }}</h1>
    <p>{{ "UNAUTHORIZED.DESCRIPTION" | bfcTranslate }}</p>
    <a class="bfc-margin-bottom-2 bfc-margin-top-2" (click)="goBack()">
        <span class="b2b-back-button">«</span>{{ "BACK_BUTTON" | bfcTranslate }}
    </a>
</bfc-single-page-layout>
